import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = "https://brcreatorstudio.app.hotmic.io";
  }, []);

  return null;
}

export default App;
